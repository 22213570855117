export var GLOBAL_ICONS;
(function(GLOBAL_ICONS) {
    GLOBAL_ICONS["actionItem"] = "list-check";
    GLOBAL_ICONS["address"] = "home";
    GLOBAL_ICONS["administrator"] = "user-gear";
    GLOBAL_ICONS["billing"] = "file-invoice-dollar";
    GLOBAL_ICONS["businessRegistration"] = "briefcase";
    GLOBAL_ICONS["businessStructure"] = "sitemap";
    GLOBAL_ICONS["client"] = "building";
    GLOBAL_ICONS["company"] = "buildings";
    GLOBAL_ICONS["compliance"] = "file-signature";
    GLOBAL_ICONS["date"] = "calendar-day";
    GLOBAL_ICONS["department"] = "building-user";
    GLOBAL_ICONS["document"] = "file-lines";
    GLOBAL_ICONS["engagedClassification"] = "check-to-slot";
    GLOBAL_ICONS["engagement"] = "poll-people";
    GLOBAL_ICONS["engagementTemplate"] = "clipboard-user";
    GLOBAL_ICONS["factor"] = "balance-scale";
    GLOBAL_ICONS["file"] = "file-lines";
    GLOBAL_ICONS["global"] = "toolbox";
    GLOBAL_ICONS["import"] = "file-import";
    GLOBAL_ICONS["insurancePolicy"] = "file-lock";
    GLOBAL_ICONS["jurisdiction"] = "globe";
    GLOBAL_ICONS["jurisdictionProcessing"] = "globe-stand";
    GLOBAL_ICONS["marketplacePartner"] = "shop";
    GLOBAL_ICONS["name"] = "input-text";
    GLOBAL_ICONS["partner"] = "handshake";
    GLOBAL_ICONS["privacy"] = "shield-keyhole";
    GLOBAL_ICONS["project"] = "diagram-project";
    GLOBAL_ICONS["role"] = "user-lock";
    GLOBAL_ICONS["settings"] = "square-sliders";
    GLOBAL_ICONS["skills"] = "user-tag";
    GLOBAL_ICONS["source"] = "arrows-down-to-people";
    GLOBAL_ICONS["stage"] = "timeline-arrow";
    GLOBAL_ICONS["status"] = "star-half-stroke";
    GLOBAL_ICONS["surveyQuestion"] = "comments-question-check";
    GLOBAL_ICONS["system"] = "server";
    GLOBAL_ICONS["tags"] = "tags";
    GLOBAL_ICONS["taxPayerStatus"] = "building-columns";
    GLOBAL_ICONS["taxRegistration"] = "cabinet-filing";
    GLOBAL_ICONS["team"] = "users";
    GLOBAL_ICONS["tenantsOptional"] = "user-check";
    GLOBAL_ICONS["test"] = "landmark-magnifying-glass";
    GLOBAL_ICONS["vendor"] = "user-tie";
    GLOBAL_ICONS["whitelabelSettings"] = "palette";
    GLOBAL_ICONS["worker"] = "user";
})(GLOBAL_ICONS || (GLOBAL_ICONS = {}));
