import { GlobalConfig } from '@shared/configs/app-global.config';
import { SystemLocaleSchemaKey } from '@dictionaries/languages';
import { merge } from 'lodash';
import type { AppConfiguration, EngagementEmbeds } from './types/app-config.type';

const subjectLocales: Array<string> = [];

Object.values(SystemLocaleSchemaKey).forEach((schemaKey) => {
	subjectLocales.push(`subject.${schemaKey}`);
});

const engagementEmbeds: EngagementEmbeds = {
	'classification.factors.factor': [],
	'classification.risksWithRecommendations.riskWithRecommendation': [],
	'classification.risksWithRecommendations.riskWithRecommendation.factors.factor': [
		'id',
		'status',
		'icon',
		'label',
		'description',
	],
	'classification.risksWithRecommendations.riskWithRecommendation.surveyQuestions.surveyQuestion':
		['_id', '_archived', '_questionType', 'status', 'worker', 'hiringManager'],
	_assignedComplianceManager: ['name'],
	client: ['id', 'name', 'roles', 'projects', 'configurations', 'compliance', 'features'],
	'client.departments': [],
	'client.teams': [],
	'client.projects': [],
	'client.stages': [],
	'client.sources': [],
	department: [],
	team: [],
	resume: [],
	stage: [],
	source: [],
	template: [],
	hiringManager: ['name'],
	'projects.project': ['name'],
	'projects.hiringManager': ['name'],
	_importedUser: ['name'],
	_jurisdiction: [
		'id',
		'type',
		'country',
		'state',
		'documents',
		'additionalProcessing',
		'marketplacePartners',
	],
	_createdUser: ['name'],
	vendor: ['name'],
};

export const AppConfig: AppConfiguration = merge(GlobalConfig, {
	ajaxTimeout: 60000,
	gridDefaultParams: {
		meta: true,
	},
	workersGridDefaultParams: {
		fields: ['externalId', 'name'],
		embeds: {
			_createdUser: ['name'],
		},
	},
	engagementGridDefaultParams: {
		fields: [
			'title',
			'externalId',
			'_status',
			'client',
			'_lastMessageDate',
			'_isMyComplianceCheckDue',
			'_myActiveProjects',
			'address',
			'progress',
			'classification.determination',
			'startDate',
			'projects',
		],
		embeds: {
			worker: ['id', 'name'],
			client: ['id', 'name'],
			vendor: ['id', 'name'],
			'projects.project': ['name'],
			_createdUser: ['id', 'name'],
		},
	},
	engagementInfoPageDefaultParams: {
		fields: [],
		embeds: {
			...engagementEmbeds,
			businessRegistration: [],
			'businessRegistration.files': [],
			taxPayerStatus: [],
			'taxPayerStatus.files': [],
			'taxRegistrations.taxRegistration': [],
			'taxRegistrations.taxRegistration.files': [],
			'insurancePolicies.insurancePolicy': [],
			'insurancePolicies.insurancePolicy.files': [],
			_createdUser: ['name'],
		},
	},
	administratorInfoPageDefaultParams: {
		embeds: {
			'companies.departments.tag': [],
			'companies.teams.tag': [],
			'companies.projects.tag': [],
			_importedUser: ['name'],
			_createdUser: ['name'],
		},
	},
	workerInfoPageDefaultParams: {
		embeds: {
			engagements: ['title', 'startDate', 'endDate'],
			businessRegistrations: [],
			'businessRegistrations.files': [],
			taxPayerStatuses: [],
			'taxPayerStatuses.files': [],
			taxRegistrations: [],
			'taxRegistrations.files': [],
			insurancePolicies: [],
			'insurancePolicies.files': [],
			_importedUser: ['name'],
			_createdUser: ['name'],
		},
	},
	engagementFindOneParams: {
		fields: [],
		embeds: engagementEmbeds,
	},
	engagementTemplateGridDefaultParams: {
		fields: ['externalId', 'title', '_updatedDate', '_updatedUser'],
		embeds: {
			_createdUser: ['name'],
			_updatedUser: ['name'],
		},
	},
	engagementTemplateFindOneDefaultParams: {
		embeds: {
			'clients.client': ['id', 'name'],
			'vendors.vendor': ['id', 'name'],
			managedCompany: ['id', 'name'],
		},
	},
	billingGridDefaultParams: {
		fields: ['externalId', 'title', '_updatedDate', '_updatedUser'],
		embeds: {
			_updatedUser: ['name'],
		},
	},
	billingFindOneDefaultParams: {
		// embeds: {
		// 	'clients.client': ['id', 'name'],
		// 	'vendors.vendor': ['id', 'name'],
		// 	managedCompany: ['id', 'name'],
		// },
	},
	fileGridDefaultParams: {
		fields: [
			'externalId',
			'name',
			'type',
			'notes',
			'_updatedDate',
			'_updatedUser',
			'_revisionCount',
		],
		embeds: {
			_createdUser: ['name'],
			_updatedUser: ['name'],
		},
	},
	clientGridDefaultParams: {
		fields: ['externalId', 'name', 'addresses'],
		embeds: {
			_createdUser: ['name'],
			// vendors: ['name'],
		},
	},
	clientInfoPageDefaultParams: {
		fields: [
			'name',
			'addresses',
			'website',
			'compliance',
			'privacy',
			'settings',
			'configurations',
			'roles',
			'features',
			'averageNumberOfContractWorkersPerYear',
			'businessTeamType',
			'businessTeamSize',
			'businessGoals',
			'mapping',
		],
		embeds: {
			_importedUser: ['name'],
			_createdUser: ['name'],
		},
	},
	vendorGridDefaultParams: {
		fields: ['externalId', 'name', 'addresses'],
		embeds: {
			_createdUser: ['name'],
			// client: ['name'],
		},
	},
	vendorInfoPageDefaultParams: {
		fields: [
			'name',
			'addresses',
			'website',
			'settings',
			'configurations',
			'roles',
			'features',
			'averageNumberOfContractWorkersPerYear',
			'businessTeamType',
			'businessTeamSize',
			'businessGoals',
			'mapping',
		],
		embeds: {
			_createdUser: ['name'],
		},
	},
	partnerGridDefaultParams: {
		fields: ['externalId', 'name', 'addresses'],
		embeds: {
			_createdUser: ['name'],
		},
	},
	partnerInfoPageDefaultParams: {
		fields: [
			'name',
			'addresses',
			'website',
			'settings',
			'configurations',
			'roles',
			'features',
			'averageNumberOfContractWorkersPerYear',
			'businessTeamType',
			'businessTeamSize',
			'businessGoals',
			'mapping',
			'type',
		],
		embeds: {
			_createdUser: ['name'],
		},
	},
	jurisdictionGridDefaultParams: {
		fields: [
			'externalId',
			'status',
			'type',
			'country',
			'state',
			'additionalProcessing.isRequired',
		],
		embeds: {
			_createdUser: ['name'],
		},
	},
	jurisdictionInfoPageDefaultParams: {
		fields: [],
		embeds: {
			'actionItems.businessRegistration.factors.factor': [],
			'actionItems.taxPayerStatus.factors.factor': [],
			'actionItems.salesTaxRegistration.factors.factor': [],
			'actionItems.workersCompensationInsurance.factors.factor': [],
			'actionItems.generalLiabilityInsurance.factors.factor': [],
			'actionItems.abilityToWorkWithoutDirectSupervision.factors.factor': [],
			'actionItems.abilityToSetWorkSchedule.factors.factor': [],
			'actionItems.expertiseToChooseWorkMethodsAndProcesses.factors.factor': [],
			'actionItems.workOutsideUsualDayToDayBusiness.factors.factor': [],
			'actionItems.independentlyEstablishedBusiness.factors.factor': [],
			'actionItems.businessRegistration.surveyQuestions.surveyQuestion': [],
			'actionItems.taxPayerStatus.surveyQuestions.surveyQuestion': [],
			'actionItems.salesTaxRegistration.surveyQuestions.surveyQuestion': [],
			'actionItems.workersCompensationInsurance.surveyQuestions.surveyQuestion': [],
			'actionItems.generalLiabilityInsurance.surveyQuestions.surveyQuestion': [],
			'actionItems.abilityToWorkWithoutDirectSupervision.surveyQuestions.surveyQuestion': [],
			'actionItems.abilityToSetWorkSchedule.surveyQuestions.surveyQuestion': [],
			'actionItems.expertiseToChooseWorkMethodsAndProcesses.surveyQuestions.surveyQuestion':
				[],
			'actionItems.workOutsideUsualDayToDayBusiness.surveyQuestions.surveyQuestion': [],
			'actionItems.independentlyEstablishedBusiness.surveyQuestions.surveyQuestion': [],
		},
	},
	automationGridDefaultParams: {
		fields: ['status', 'externalId', 'name', '_lastRunDate', '_updatedDate'],
		embeds: {
			_createdUser: ['name'],
		},
	},
	emailTemplateGridDefaultParams: {
		fields: [
			'status',
			'externalId',
			'name',
			...subjectLocales,
			'_lastSentDate',
			'_updatedDate',
		],
		embeds: {
			_createdUser: ['name'],
		},
	},
	emailTemplateInfoPageDefaultParams: {
		fields: ['json', 'html', ...subjectLocales],
		embeds: {
			'clients.client': [],
			'vendors.vendor': [],
			managedCompany: ['id', 'name'],
		},
	},
	smsTemplateGridDefaultParams: {
		fields: ['status', 'externalId', 'name', '_lastSentDate', '_updatedDate'],
		embeds: {
			_createdUser: ['name'],
		},
	},
	apiKeyGridDefaultParams: {
		fields: ['externalId', 'label', 'expirationDate', '_createdDate', '_lastUsedDate'],
		embeds: {
			_createdUser: ['name'],
		},
	},
	administratorGridDefaultParams: {
		fields: ['externalId', 'name', 'email', 'phones'],
		embeds: {
			_createdUser: ['name'],
		},
	},
	roleGridDefaultParams: {
		fields: ['externalId', 'label', 'description'],
		embeds: {
			_createdUser: ['name'],
		},
	},
	userDefinedTagGridDefaultParams: {
		fields: ['externalId', 'label', 'description'],
		embeds: {
			_createdUser: ['name'],
		},
	},
	sourceGridDefaultParams: {
		fields: ['externalId', 'name', 'color', 'description'],
		embeds: {
			_createdUser: ['name'],
		},
	},
	stageGridDefaultParams: {
		fields: ['externalId', 'name', 'color', 'description'],
		embeds: {
			_createdUser: ['name'],
		},
	},
	departmentGridDefaultParams: {
		fields: ['externalId', 'name', 'color', 'description'],
		embeds: {
			_createdUser: ['name'],
		},
	},
	teamGridDefaultParams: {
		fields: ['externalId', 'name', 'color', 'description'],
		embeds: {
			_createdUser: ['name'],
		},
	},
	projectGridDefaultParams: {
		fields: ['externalId', 'name', 'color', 'description'],
		embeds: {
			_createdUser: ['name'],
		},
	},
	factorGridDefaultParams: {
		embeds: {
			_createdUser: ['name'],
		},
	},
	testGridDefaultParams: {
		embeds: {
			_createdUser: ['name'],
		},
	},
	businessRegistrationGridDefaultParams: {
		embeds: {
			_createdUser: ['name'],
		},
	},
	taxPayerStatusGridDefaultParams: {
		embeds: {
			_createdUser: ['name'],
		},
	},
	taxRegistrationGridDefaultParams: {
		embeds: {
			_createdUser: ['name'],
		},
	},
	insurancePolicyGridDefaultParams: {
		embeds: {
			_createdUser: ['name'],
		},
	},
	muiApiKey:
		'a51d976c09efd75dab87975c8b007e17Tz01NTIzMSxFPTE3MDEzMDc5MDkyNjMsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=', // process.env.NEXT_PUBLIC_MUI_API_KEY as string,
	emsi: {
		clientId: '5afn1kugs09aauig',
		clientSecret: '1aYakPdB',
	},
	links: {
		contact: 'https://independently.ai',
		docs: 'https://independently.readme.io/docs',
		help: 'https://help.independently.ai',
		legal: 'https://independently.ai',
		privacyPolicy: 'https://independently.ai/privacy-policy/',
		status: 'https://status.independently.ai',
		termsOfService: 'https://independently.ai/terms-of-service/',
		termsOfUse: 'https://independently.ai/terms-of-use/',
		website: 'https://independently.ai',
	},
});
