// import i18n from 'libs/localization/i18n-instance.service';
import { Map } from 'immutable';
import { BaseDictionary } from './types/dictionaries.type';
import { IdNamespace } from '../shared/database/constants/id-namespaces.constant';

export enum DateFormat {
	MM_DD_YYYY = 'mm-dd-yyyy',
	// YYYY_MM_DD = 'yyyy-mm-dd',
	// DD_MM_YYYY = 'dd-mm-yyyy',
}

// https://mui.com/x/react-date-pickers/adapters-locale/#custom-formats
export enum DateFieldFormat {
	MM_DD_YYYY = 'MM/DD/YYYY',
	// YYYY_MM_DD = 'YYYY/MM/DD',
	// DD_MM_YYYY = 'DD/MM/YYYY',
}

export interface dateFormatItem extends BaseDictionary {
	code: DateFormat;
	id: `${IdNamespace.DateFormat}_${string}`;
	order: number;
	label: string;
	format: string;
	// https://mui.com/x/react-date-pickers/adapters-locale/#getting-started
	datePickerOptions: {
		format: DateFieldFormat;
	};
}

export const dateFormats = Map<DateFormat | `${DateFormat}`, dateFormatItem>({
	[DateFormat.MM_DD_YYYY]: {
		code: DateFormat.MM_DD_YYYY,
		id: 'dafor_2FeLvfrc739Qgm67X6GsVtvHEHU',
		order: 1,
		// label: i18n.t('dateFormats.month_day_year', { ns: 'dictionaries' }),
		label: 'MM/DD/YYYY',
		format: 'MM/DD/YYYY',
		datePickerOptions: {
			format: DateFieldFormat.MM_DD_YYYY,
		},
	},
	// [DateFormat.YYYY_MM_DD]: {
	// 	code: DateFormat.YYYY_MM_DD,
	// 	id: 'dafor_2FeLvdv6rZsrlWskvjUsuY0v4Ka',
	// 	order: 2,
	// 	// label: i18n.t('dateFormats.year_month_day', { ns: 'dictionaries' }),
	// 	label: 'YYYY/MM/DD',
	// 	format: 'YYYY/MM/DD',
	// 	datePickerOptions: {
	// 		format: DateFieldFormat.YYYY_MM_DD,
	// 	},
	// },
	// [DateFormat.DD_MM_YYYY]: {
	// 	code: DateFormat.DD_MM_YYYY,
	// 	id: 'dafor_2FeLvhW4edq979iCL5dbn0ZvHVk',
	// 	order: 2,
	// 	// label: i18n.t('dateFormats.day_month_year', { ns: 'dictionaries' }),
	// 	label: 'DD/MM/YYYY',
	// 	format: 'DD/MM/YYYY',
	// 	datePickerOptions: {
	// 		format: DateFieldFormat.DD_MM_YYYY,
	// 	},
	// },
});
